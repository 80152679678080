import React, { useEffect, useRef, useState } from "react";
import emailjs from "emailjs-com";
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css";
import { useNavigate } from "react-router-dom";
const ContactSection = () => {
  const [alertMessage, setAlertMessage] = useState("");
  const [buttonText, setButtonText] = useState("Send Message");
  const [alertType, setAlertType] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef();
  const [mobileNumber, setMobileNumber] = useState("");
  const [error, setError] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const handleMobileNumberChange = (e) => {
    const value = e.target.value;
    setMobileNumber(value);
    setError("");
  };

  const validateMobileNumber = () => {
    const ukMobileRegex = /^0\d{10}$/;

    if (!ukMobileRegex.test(mobileNumber)) {
      setError("Please enter a valid 11 digit UK mobile number.");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setButtonText("Sending...");

    emailjs
      .sendForm(
        "service_2cnqd96",
        "template_bcda4fr",
        e.target,
        "nQBJzdhm_0rQ4QbLv"
      )
      .then(
        (result) => {
          setAlertMessage(
            "Thank you for contacting, one of our team will be in touch soon!"
          );
          setAlertType("success");
          // setShowAlert(true);
          e.target.reset();
          setButtonText("Send Message");
          // setShowModal(true);
          navigate("/thank_you");
        },
        (error) => {
          setAlertMessage("Failed to send message. Please try again.");
          setAlertType("danger");
          setShowAlert(true);
          alert("Error during form submission:", error);
          setButtonText("Send Message");
        }
      );
  };

  const handleScroll = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleScroll, {
      threshold: 0.1, // Adjust threshold as needed
    });
    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <div
      className="row"
      ref={ref}
      id="contact-us"
      style={{ marginTop: "-150px", marginBottom: "50px" }}
    >
      <div className="col-lg-12">
        <div className="container py-5 px-4">
          <div className="card card-body border-0 p-5 rounded-4">
            <h1
              className={`text-theme mb-5 animate__animated ${
                isVisible ? "animate__fadeIn" : "animate__fadeOut"
              }`}
            >
              Let’s start your smile journey together
            </h1>
            {showAlert && (
              <div
                className={`alert alert-${alertType} alert-dismissible fade show`}
                role="alert"
              >
                {alertMessage}
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowAlert(false)}
                ></button>
              </div>
            )}
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Name*
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      className="form-control"
                      placeholder="Your Name"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="phoneNumber" className="form-label">
                      Phone Number*
                    </label>
                    <input
                      type="text"
                      name="phone_number"
                      id="phoneNumber"
                      pattern="\d{11}"
                      title="Phone number must be 11 digits"
                      className="form-control"
                      placeholder="Your Phone Number"
                      required
                    />
                    {error && <small className="text-danger">{error}</small>}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Email*
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="form-control"
                      placeholder="Your Email"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="treatment" className="form-label">
                      Treatment You're Interested In*
                    </label>
                    <select
                      className="form-select"
                      name="treatment"
                      id="treatment"
                      required
                    >
                      <option value="">Select A Treatment</option>
                      <option value="General Dentistry">
                        General Dentistry
                      </option>
                      <option value="Invisalign®">Invisalign®</option>
                      <option value="NiTime Aligners">NiTime Aligners</option>
                      <option value="Dental Implants">Dental Implants</option>
                      <option value="All on 4 Dental Implants">
                        All on 4 Dental Implants
                      </option>
                      <option value="Composite Bonding">
                        Composite Bonding
                      </option>
                      <option value="Porcelain Veneers">
                        Porcelain Veneers
                      </option>
                      <option value="Teeth Whitening">Teeth Whitening</option>
                      <option value="Restorative Dentistry">
                        Restorative Dentistry
                      </option>
                      <option value="Root Canal Treatments">
                        Root Canal Treatments
                      </option>
                      <option value="Dental Plan">Dental Plan</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label htmlFor="message" className="form-label">
                      How can we help?*
                    </label>
                    <textarea
                      className="form-control"
                      name="message"
                      id="message"
                      rows="3"
                      placeholder="Your message..."
                      required
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="form-check mb-4">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="agree"
                  id="agree"
                  required
                />
                <label className="form-check-label" htmlFor="agree">
                  Please tick this box to agree to our{" "}
                  <a
                    href="/privacy-policy"
                    target="_blank"
                    className="text-golden"
                  >
                    privacy policy
                  </a>{" "}
                  before continuing.
                </label>
              </div>
              <div className="d-grid gap-2 mt-3">
                <button
                  od="submit-btn"
                  type="submit"
                  className={`btn btn-theme animate__animated ${
                    isVisible ? "animate__fadeIn" : "animate__fadeOut"
                  }`}
                >
                  {buttonText}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Success Modal */}
      {showModal && (
        <div
          className="modal d-block border-0"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content bg-success text-white">
              <div className="modal-header bg-theme d-flex justify-content-end border-0">
                <a type="button" onClick={() => setShowModal(false)}>
                  <i class="fa fa-times text-white" aria-hidden="true"></i>
                </a>
              </div>
              <div className="modal-body bg-theme p-4 text-center">
                <p className="fs-20">
                  <span className="text-golden">Thank you</span> for getting in
                  touch, one of the team will be in touch soon!
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactSection;
