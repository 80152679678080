import { Helmet } from "react-helmet";
import Navbar from "../components/universal/navbar";
import Header from "../components/dental-implants/hero";
import WhatAndWhy from "../components/dental-implants/what-and-why";
import Process from "../components/dental-implants/process";
import AfterCare from "../components/dental-implants/aftercare";
import CTA from "../components/dental-implants/cta";
import Contact from "../components/universal/contact";
import Footer from "../components/universal/footer";
import TransformYourSmile from "../components/dental-implants/transform-your-smile";

function Index() {
  return (
    <div>
      <Helmet>
        <title>Dental Implants in Ilkley, West Yorkshire | Regent Dental</title>
        <meta
          name="description"
          content="Regent Dental provides high-quality dental implants, designed to replace missing teeth with a natural look and feel, ensuring long-lasting results for your smile."
        />
      </Helmet>
      <Navbar />
      <Header />
      <WhatAndWhy />
      <Process />
      <TransformYourSmile />
      <AfterCare />
      <CTA />
      <Contact heading={"Book your dental implant consultation"} />
      <Footer />
    </div>
  );
}

export default Index;
