import React from "react";
import Navbar from "../components/universal/navbar";
import Footer from "../components/universal/footer";
import Header from "../components/fee/feepageHero";
const Thankyou = () => {
  return (
    <>
      <Navbar />
      <Header heading />
      <Footer />
    </>
  );
};

export default Thankyou;
