import React, { useEffect, useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css";
import docImage from "../../assets/img/doc-white.png"; // Import your image

const DentalCareSection = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null); // Reference to the section for intersection observer

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true); // Trigger animation when in view
        } else {
          setIsVisible(false); // Reset visibility when out of view
        }
      },
      { threshold: 0.1 } // Adjust threshold as needed
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <section ref={sectionRef} className="bg-theme">
      <div className="container">
        <div className="row align-items-center">
          <div
            className={`col-md-6 mt-4 animate__animated ${
              isVisible ? "animate__fadeInLeft" : "animate__fadeOut"
            }`}
          >
            <img
              src={docImage}
              className="img-fluid"
              alt="Receptionist at Regent Dental Care"
            />
          </div>

          <div
            className={`col-md-6 text-light mt-4 animate__animated ${
              isVisible ? "animate__fadeInRight" : "animate__fadeOut"
            }`}
          >
            <p>Dental Care You'll Love</p>

            <h1 className="fs-48">Get Rid of Gaps with Dental Implants</h1>

            <p>
              {/* Dental implants are artificial titanium tooth roots that are surgically inserted into the jawbone. They provide a strong foundation for fixed or removable replacement teeth, such as crowns, bridges, or dentures, that are designed to match your natural teeth in appearance and function. */}
              Dental implants are artificial titanium tooth roots that are
              surgically inserted into the jawbone. They provide a strong
              foundation for fixed or removable replacement teeth, such as
              crowns, bridges, or dentures, that are designed to match your
              natural teeth in appearance and function.
            </p>
            <p>
              {/* Dental implants are a durable, long-term solution for replacing
              missing teeth. They help preserve the jawbone, preventing bone
              loss and maintaining facial structure, while providing a stable
              foundation for chewing and speaking. With a high success rate,
              implants are an excellent choice for filling missing gaps. */}
              Dental implants are a durable, long-term solution for replacing
              missing teeth. They help preserve the jawbone, preventing bone
              loss and maintaining facial structure, while providing a stable
              foundation for chewing and speaking. With a high success rate,
              implants are an excellent choice for filling missing gaps. Regent
              Dental are a leading provider of dental implants in Ilkley and the
              surrounding areas, offering tailored treatment plans and
              professional aftercare.
            </p>

            <a href="/contact-us" className="btn btn-golden border-0 mt-4 mb-5">
              Book an Appointment
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DentalCareSection;
