import React, { useEffect, useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css";

const GeneralDentistryProcess = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <section
      ref={sectionRef}
      className="d-flex justify-content-center align-items-center"
      style={{ backgroundColor: "#fff", minHeight: "95vh" }}
    >
      <div className="container">
        <div className="text-center">
          <h1
            className={`mt-5 text-theme animate__animated ${
              isVisible ? "animate__zoomIn" : "animate__fadeOut"
            }`}
          >
            <span class="text-golden">Dental Plans</span> To Choose From
          </h1>
          <p
            className={`w-75 mx-auto animate__animated ${
              isVisible ? "animate__zoomIn" : "animate__fadeOut"
            }`}
          >
            At Regent Dental we believe in a proactive and preventative approach
            to dental care. Using the very latest technology to provide
            efficient, effective treatments, whilst providing a comfortable
            experience for patients of all ages.
          </p>
        </div>

        <div className="p-4">
          <div className="row">
            {[
              {
                price: "£11.95",
                title: "Plan 1",
                dental_examination: "1 x",
                appointments: "2 x",
              },
              {
                price: "£22.95",
                title: "Plan 2",
                dental_examination: "2 x",
                appointments: "2 x",
                tooth: "Toothbrush",
              },
              {
                price: "£36.95",
                title: "Plan 3",
                dental_examination: "2 x",
                appointments: "4 x",
                tooth: " Toothbrush",
              },
            ].map((service, index) => (
              <div className="col-md-4 mb-4" key={index}>
                <div
                  className={`card shadow card-body border-0 rounded-4 py-5 animate__animated ${
                    isVisible ? "animate__zoomIn" : "animate__fadeOut"
                  }`}
                  style={{ height: "410px" }}
                >
                  <div class="mb-5">
                    <div className="fs-30 fw-600 mb-3 text-center text-theme">
                      {service.title}
                    </div>
                    <div className="table-responsive">
                      <table className="table table-borderless">
                        <thead>
                          <tr>
                            <th scope="col" width="90%">
                              {service.dental_examination}{" "}
                              <span className="fw-normal">
                                Dental examination <strong>Per year</strong>
                              </span>
                            </th>
                          </tr>
                          <tr>
                            <th scope="col" width="90%">
                              {service.appointments}{" "}
                              <span className="fw-normal">
                                {" "}
                                Dental Hygiene visits <strong>Per year</strong>
                              </span>
                            </th>
                          </tr>
                          <tr>
                            <th scope="col" width="90%">
                              {" "}
                              <span className="fw-normal">
                                <strong>10% off Treatment</strong> (excluding
                                cosmetic and implant treatments)
                              </span>
                            </th>
                          </tr>
                          {service.tooth && (
                            <tr>
                              <th scope="col" width="90%">
                                {" "}
                                <span className="fw-normal">
                                  <strong>Free Electric</strong> {service.tooth}
                                </span>
                              </th>
                            </tr>
                          )}
                        </thead>
                      </table>
                    </div>
                  </div>
                  <div
                    style={{ height: "70px", width: "92%" }}
                    className="position-fixed bottom-0 bg-theme text-golden rounded-3 d-flex align-items-center justify-content-center fw-700 fs-34 text-center mb-3"
                  >
                    <span>
                      {service.price}
                      <span className="fs-16">/month</span>
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default GeneralDentistryProcess;
