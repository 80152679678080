import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";
import "../../assets/css/team.css";
import "animate.css";
import p1 from "../../assets/img/partners/1.jpg";
import p2 from "../../assets/img/partners/2.png";
import p3 from "../../assets/img/partners/3.png";
import p4 from "../../assets/img/partners/4.png";
import p5 from "../../assets/img/partners/5.png";
import p6 from "../../assets/img/partners/6.png";
import p7 from "../../assets/img/partners/7.png";
import p8 from "../../assets/img/partners/8.png";

const ProfileSection = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <section>
      {/* Partner Logos Section */}
      <div className="container mt-5 mb-5">
        <div className="text-center mb-5">
          <h1 className="text-theme">
            {" "}
            The <span className="text-golden">Partners</span> We Work With
          </h1>
        </div>

        <Swiper
          slidesPerView={4}
          spaceBetween={70}
          loop={true}
          autoplay={{
            delay: 1500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}
          className="mySwiper"
        >
          <SwiperSlide>
            <a
              href="https://www.biohorizonscamlog.co.uk/en-gb/"
              rel="noreferrer"
              target="_blank"
            >
              <img src={p1} height="50" alt="Biohorizons Camlog logo" />
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a
              href="https://www.invisalign.co.uk/"
              rel="noreferrer"
              target="_blank"
            >
              <img src={p2} height="50" alt="Invisalign logo" />
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a
              href="https://www.carestreamdental.com/en-gb/"
              rel="noreferrer"
              target="_blank"
            >
              <img src={p3} height="50" alt="Carestream Dental logo" />
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="https://www.medit.com/" rel="noreferrer" target="_blank">
              <img src={p4} height="50" alt="Medit logo" />
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="https://smilefast.com/" rel="noreferrer" target="_blank">
              <img
                src={p5}
                height="50"
                alt="SmileFast composite bonding logo"
              />
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a
              href="https://patient.enlightensmiles.com/"
              rel="noreferrer"
              target="_blank"
            >
              <img src={p6} height="50" alt="Enlighten Smiles logo" />
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a
              href="https://www.straumann.com/neodent/gb/en/professionals.html"
              rel="noreferrer"
              target="_blank"
            >
              <img src={p7} height="50" alt="Neodent logo" />
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a
              href="https://boutiquewhitening.com/"
              rel="noreferrer"
              target="_blank"
            >
              <img src={p8} height="50" alt="Boutique Whitening logo" />
            </a>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
};

export default ProfileSection;
