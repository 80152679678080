import React, { useEffect, useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css";
import docImage from "../../assets/img/doc-white.png"; // Import your image

const DentalCareSection = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null); // Reference to the section for intersection observer

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true); // Trigger animation when in view
        } else {
          setIsVisible(false); // Reset visibility when out of view
        }
      },
      { threshold: 0.1 } // Adjust threshold as needed
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <section ref={sectionRef} style={{ backgroundColor: "#FFFBF1" }}>
      <div className="container">
        <div className="row align-items-center">
          <div
            className={`col-md-6 mt-4 animate__animated ${
              isVisible ? "animate__fadeInLeft" : "animate__fadeOut"
            }`}
          >
            <img
              src={docImage}
              className="img-fluid"
              alt="Receptionist at Regent Dental Care"
            />
          </div>

          <div
            className={`col-md-6 text-light mt-4 animate__animated ${
              isVisible ? "animate__fadeInRight" : "animate__fadeOut"
            }`}
          >
            <h1 className="fs-44 text-theme">
              <span className="text-golden">What</span> is Denplan Essentials?
            </h1>

            <p className="text-theme">
              Denplan Essentials is a maintenance plan to give you affordable
              access to private dental care. You pay a monthly fee that covers a
              set number dental check-ups, dental hygiene visits and dental
              x-rays. You can sign up quickly and easily even if you have
              outstanding treatment.
            </p>
            <a href="/contact-us" className="btn btn-golden border-0 mt-4 mb-5">
              Book an Appointment
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DentalCareSection;
