import React, { useEffect, useState } from "react";
import "animate.css";
import Img1 from "../../assets/img/Rectangle-15.png";
import stars from "../../assets/img/64da982b675c905fb3365a18_swish-stars.svg fill.png";

const GeneralDentistry = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <section>
      <div
        className="d-flex justify-content-start align-items-center"
        style={{
          backgroundImage: `url(${Img1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100vh",
        }}
      >
        <div className="container text-light text-start">
          <img
            src={stars}
            className={`img-fluid mb-2 animate__animated ${
              isVisible ? "animate__zoomIn" : "animate__fadeOut"
            }`}
            alt=""
          />
          <h1
            className={`fs-60 animate__animated ${
              isVisible ? "animate__zoomIn" : "animate__fadeOut"
            }`}
          >
            Root Canal Treatment
          </h1>
          <p
            className={`mt-3 fs-16 animate__animated ${
              isVisible ? "animate__zoomIn" : "animate__fadeOut"
            }`}
          >
            At Regent Dental we understand that the words 'root canal' can cause
            a bit of concern. But thanks to advances in dental technology and
            the expertise of our dentists, a root canal treatment is now as
            straightforward as getting a filling.
          </p>
          <div className="d-flex justify-content-start">
            <a
              href="https://onlineappointment.carestack.uk/?dn=regent#/select-reason"
              target="_blank"
              rel="noreferrer"
              className="btn-theme border-0 mt-3 animate__animated animate__fadeIn"
              type="button"
            >
              Book a Consultation
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GeneralDentistry;
