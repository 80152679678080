import React, { useEffect, useState } from "react";
import "animate.css";
import Img1 from "../../assets/img/Rectangle-15.png";
import stars from "../../assets/img/64da982b675c905fb3365a18_swish-stars.svg fill.png";

const GeneralDentistry = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <section>
      <div
        className="d-flex justify-content-start align-items-center"
        style={{
          backgroundImage: `url(${Img1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100vh",
        }}
      >
        <div className="container text-light text-start">
          <img
            src={stars}
            className={`img-fluid mb-2 animate__animated ${
              isVisible ? "animate__zoomIn" : "animate__fadeOut"
            }`}
            alt=""
          />
          <h1
            className={`fs-60 animate__animated ${
              isVisible ? "animate__zoomIn" : "animate__fadeOut"
            }`}
          >
            All On 4 Dental Implants
          </h1>
          <p
            className={`mt-3 fs-16 animate__animated ${
              isVisible ? "animate__zoomIn" : "animate__fadeOut"
            }`}
          >
            Regent Dental are a leading provider of all on four dental implants
            in West Yorkshire. We are proud to offer all on four dental implants
            for patients who need to replace an entire arch of teeth, providing
            a natural-looking and long-lasting alternative to traditional
            dentures.
          </p>
          <div className="d-flex justify-content-start">
            <a
              href="/contact-us"
              className="btn-theme border-0 mt-3 animate__animated animate__fadeIn"
              type="button"
            >
              Book an Appointment
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GeneralDentistry;
