// src/components/SmileSection.js
import React, { useEffect, useRef, useState } from "react";
import Hero from "../../assets/img/reception.jpg";
import "animate.css";
import "../../assets/css/terms-of-use.css";
const SmileSection = ({ heading }) => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          } else {
            setIsVisible(false); // Set to false when the section is not visible
          }
        });
      },
      { threshold: 0.1 } // Adjust the threshold as needed
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <div
      className={`d-flex justify-content-center align-items-center ${
        heading ? "thanks-header" : ""
      }`}
      style={{
        backgroundImage: `url(${Hero})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: heading ? "auto" : "60vh",
      }}
      ref={sectionRef}
    >
      <div className="text-light text-center mt-5">
        {heading ? (
          <h1
            className={`heading-tankyou ${
              isVisible
                ? "animate__animated animate__zoomIn"
                : "animate__animated animate__fadeOut"
            }`}
          >
            <span className="text-golden">Thank you </span>
            for getting in touch, <br /> one of the team will be in touch soon!
          </h1>
        ) : (
          <h1
            className={`fs-60 ${
              isVisible
                ? "animate__animated animate__zoomIn"
                : "animate__animated animate__fadeOut"
            }`}
          >
            Your <span className="text-golden"> Smile, </span>
            Your <span className="text-golden"> Investment,</span> <br></br>
            Our <span className="text-golden"> Transparency.</span>
          </h1>
        )}
      </div>
    </div>
  );
};

export default SmileSection;
