// src/components/DentalImplantReferralForm.js
import React, { useEffect, useRef, useState } from "react";
import "animate.css";
import emailjs from "emailjs-com";
import { Link } from "react-router-dom";

const DentalImplantReferralForm = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [formStatus, setFormStatus] = useState(null);
  const [buttonText, setButtonText] = useState("Send Message");
  const formRef = useRef(null);
  const [mobileNumber, setMobileNumber] = useState("");
  const [error, setError] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleMobileNumberChange = (e) => {
    const value = e.target.value;
    setMobileNumber(value);
    setError("");
  };

  const validateMobileNumber = () => {
    const ukMobileRegex = /^0\d{10}$/;

    if (!ukMobileRegex.test(mobileNumber)) {
      setError("Please enter a valid 11 digit UK mobile number.");
    }
  };

  // Setting today's date
  const today = new Date();
  const formattedDate = `${String(today.getDate()).padStart(2, "0")}/${String(
    today.getMonth() + 1
  ).padStart(2, "0")}/${today.getFullYear()}`;

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          } else {
            setIsVisible(false);
          }
        });
      },
      { threshold: 0.1 }
    );

    if (formRef.current) {
      observer.observe(formRef.current);
    }

    return () => {
      if (formRef.current) {
        observer.unobserve(formRef.current);
      }
    };
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setButtonText("Sending...");

    emailjs
      .sendForm(
        "service_2cnqd96",
        "template_t5jn3a7",
        e.target,
        "nQBJzdhm_0rQ4QbLv"
      )
      .then(
        (result) => {
          setFormStatus({
            type: "success",
            message: "Form submitted successfully!",
          });
          e.target.reset();
          setShowModal(true);
          setButtonText("Send Message");
        },
        (error) => {
          setFormStatus({
            type: "error",
            message: "There was an issue submitting the form.",
          });
          console.error("Error during form submission:", error);
          alert("Error during form submission:", error);
          setButtonText("Send Message");
        }
      );
  };

  return (
    <section className="container" style={{ marginTop: "50px" }}>
      <div className="row" ref={formRef}>
        <div
          className={`col-md-3 mt-3 ${
            isVisible ? "animate__animated animate__fadeInLeft" : ""
          }`}
        >
          <div className="list-group rounded-0">
            <Link
              to="/referral/dental-implant"
              className="list-group-item list-group-item-action bg-theme text-white p-3"
              aria-current="true"
            >
              Dental Implant Referral Form
            </Link>
            <Link
              to="/referral/extractions"
              className="list-group-item list-group-item-action p-3"
            >
              Extractions Referral Form
            </Link>
            {/* <Link
              to="/referral/friend"
              className="list-group-item list-group-item-action p-3"
              aria-current="true"
            >
              Friend Referral Form
            </Link> */}
            <Link
              to="/referral/cbct"
              className="list-group-item list-group-item-action p-3"
            >
              CBCT Referral Form
            </Link>
          </div>
        </div>

        <div
          className={`col-md-9 ${
            isVisible ? "animate__animated animate__fadeInRight" : ""
          }`}
        >
          <form ref={formRef} onSubmit={handleSubmit}>
            <div className="card border-0 rounded-3">
              <div className="card-body">
                <h1 className="fw-400">Dental Implant Referral Form</h1>
                <p>
                  Please complete the form below and we will contact the patient
                  as soon as possible:
                </p>

                <h4 className="border-3 border-bottom py-2 mb-3 fw-400">
                  Referring Dentist Details
                </h4>
                <input
                  type="hidden"
                  name="referral_name"
                  value="Dental Implants"
                />
                {/** Referring Dentist Details Fields */}
                {[
                  {
                    label: "Referring Dentist:*",
                    type: "text",
                    name: "referring_dentist",
                  },
                  {
                    label: "Practice Name:*",
                    type: "text",
                    name: "practice_name",
                  },
                  {
                    label: "Practice Address 1:*",
                    type: "text",
                    name: "practice_address_1",
                  },
                  {
                    label: "Practice Address 2:*",
                    type: "text",
                    name: "practice_address_2",
                  },
                  {
                    label: "Practice Postcode:*",
                    type: "text",
                    name: "practice_postcode",
                    className: "w-50",
                  },
                  {
                    label: "Practice Phone Number:*",
                    type: "text",
                    name: "practice_phone_number",
                    error: "Please enter a valid 11 digit UK mobile number.",
                  },
                  {
                    label: "Practice Email:*",
                    type: "email",
                    name: "practice_email",
                  },
                ].map((field, index) => (
                  <div key={index} className="row g-2 align-items-center mb-2">
                    <div className="col-sm-3 text-end fs-14">
                      <label className="col-form-label">{field.label}</label>
                    </div>
                    <div className="col-sm-9">
                      {index === 5 ? (
                        <div>
                          <input
                            type={field.type}
                            className={`form-control rounded-0 ${
                              field.className || ""
                            }`}
                            name={field.name}
                            required
                            pattern="\d{11}"
                            title="Phone number must be 11 digits"
                          />
                          {field.error && (
                            <small className="text-danger">{error}</small>
                          )}
                        </div>
                      ) : (
                        <input
                          type={field.type}
                          className={`form-control rounded-0 ${
                            field.className || ""
                          }`}
                          name={field.name}
                          required
                        />
                      )}
                    </div>
                  </div>
                ))}

                {/** Patient Details and Summary */}
                <div className="row mt-4">
                  <div className="col-md-6">
                    <h4 className="border-3 border-bottom py-2 mb-3 fw-400">
                      Patient Details
                    </h4>
                    {[
                      {
                        label: "Patient Name:*",
                        type: "text",
                        name: "patient_name",
                      },
                      {
                        label: "Telephone:*",
                        type: "text",
                        name: "patient_telephone",
                      },
                      {
                        label: "Mobile:*",
                        type: "text",
                        name: "patient_mobile",
                        error:
                          "Please enter a valid 11 digit UK mobile number.",
                      },
                      {
                        label: "Email:*",
                        type: "email",
                        name: "patient_email",
                      },
                      {
                        label: "Date of Birth:*",
                        type: "date",
                        name: "patient_dob",
                      },
                    ].map((field, index) => (
                      <div
                        key={index}
                        className="row g-2 align-items-center mb-2"
                      >
                        <div className="col-sm-4 text-end fs-14">
                          <label className="col-form-label">
                            {field.label}
                          </label>
                        </div>
                        <div className="col-sm-8">
                          {index === 2 ? (
                            <div>
                              <input
                                type={field.type}
                                className={`form-control rounded-0 ${
                                  field.className || ""
                                }`}
                                name={field.name}
                                required
                                pattern="\d{11}"
                                title="Phone number must be 11 digits"
                              />
                              {field.error && (
                                <small className="text-danger">{error}</small>
                              )}
                            </div>
                          ) : (
                            <input
                              type={field.type}
                              className={`form-control rounded-0 ${
                                field.className || ""
                              }`}
                              name={field.name}
                              required
                            />
                          )}
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="col-md-6">
                    <h4 className="border-3 border-bottom py-2 mb-3 fw-400">
                      Short Summary
                    </h4>
                    <textarea
                      className="form-control rounded-0"
                      rows="9"
                      name="short_summary"
                      required
                    ></textarea>
                  </div>
                </div>

                {/** More Information */}
                <div className="row mt-4">
                  <h4 className="border-3 border-bottom py-2 mb-3 fw-400">
                    More Information
                  </h4>
                  <div className="row g-2 align-items-center mb-2">
                    <div className="col-sm-4 text-end fs-14"></div>
                    <div className="col-sm-8">
                      {[
                        "Implant Placement Only",
                        "Implant Placement and Restoration",
                        "Implant Placement and Mentoring for Restoration",
                      ].map((option, index) => (
                        <div key={index} className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="more_info"
                            value={option}
                          />
                          <label className="form-check-label">{option}</label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                {/** Special Instructions */}
                <div className="row mt-4">
                  <h4 className="border-3 border-bottom py-2 mb-3 fw-400">
                    Special Instructions
                  </h4>
                  <textarea
                    className="form-control rounded-0"
                    rows="5"
                    name="special_instructions"
                    required
                  ></textarea>

                  {/** Practitioner Name and Date */}
                  <div className="row g-2 align-items-center mb-2">
                    <div className="col-sm-3 text-end fs-14">
                      <label className="col-form-label">
                        Practitioner Name:
                      </label>
                    </div>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control rounded-0"
                        name="practitioner_name"
                        required
                      />
                    </div>
                  </div>
                  <div className="row g-2 align-items-center mb-2">
                    <input
                      type="hidden"
                      name="date"
                      value={formattedDate}
                    ></input>
                    <div className="col-sm-3 text-start fs-14 text-muted">
                      Date {formattedDate}
                    </div>
                    <div className="col-sm-9">
                      <input type="checkbox" required />
                      <label className="col-form-label ms-2">
                        Please tick this box to agree to our{" "}
                        <a
                          href="/privacy-policy"
                          target="_blank"
                          className="text-golden"
                        >
                          privacy policy
                        </a>{" "}
                        before continuing.
                      </label>
                    </div>
                  </div>
                </div>

                {/** Submit and Clear Buttons */}
                <div className="row">
                  <div className="col-md-3 d-grid">
                    <button
                      type="reset"
                      className="btn btn-light me-2 rounded-0 w-100"
                    >
                      Clear
                    </button>
                  </div>
                  <div className="col-md-9">
                    <button
                      type="submit"
                      className="btn btn-theme rounded-0 w-100"
                    >
                      {buttonText}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* Success Modal */}
      {showModal && (
        <div
          className="modal d-block border-0"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content bg-success text-white">
              <div className="modal-header bg-theme d-flex justify-content-end border-0">
                <a type="button" onClick={() => setShowModal(false)}>
                  <i class="fa fa-times text-white" aria-hidden="true"></i>
                </a>
              </div>
              <div className="modal-body bg-theme p-4 text-center">
                <p className="fs-20">
                  <span className="text-golden">Thank you</span> for getting in
                  touch, one of the team will be in touch soon!
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default DentalImplantReferralForm;
