import React from "react";
import { Helmet } from "react-helmet";
import Navbar from "../components/universal/navbar";
import Header from "../components/all-on-4-implants/hero";
import WhatAndWhy from "../components/all-on-4-implants/what-and-why";
import Process from "../components/all-on-4-implants/process";
import AfterCare from "../components/all-on-4-implants/aftercare";
import Benifits from "../components/all-on-4-implants/benefits";
import CTA from "../components/all-on-4-implants/cta";
import Contact from "../components/universal/contact";
import Footer from "../components/universal/footer";

function Index() {
  return (
    <React.Fragment>
      <Helmet>
        <title>All-on-Four Dental Implants in Ilkley | Regent Dental</title>
        <meta
          name="description"
          content="At Regent Dental, we specialize in full arch implant dentistry, providing patients with a lasting, natural-looking solution to replace an entire arch of teeth."
        />
      </Helmet>
      <Navbar />
      <Header />
      <WhatAndWhy />
      <Process />
      <AfterCare />
      <Benifits />
      <CTA />
      <Contact heading={"Book your all on four dental implant consultation"} />
      <Footer />
    </React.Fragment>
  );
}

export default Index;
