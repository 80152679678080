// src/components/DentalImplantReferralForm.js
import React, { useEffect, useRef, useState } from "react";
import "animate.css";
import emailjs from "emailjs-com";
import { Link } from "react-router-dom";

const DentalImplantReferralForm = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [formStatus, setFormStatus] = useState(null);
  const [buttonText, setButtonText] = useState("Send Message");
  const formRef = useRef(null);
  const [mobileNumber, setMobileNumber] = useState("");
  const [error, setError] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleMobileNumberChange = (e) => {
    const value = e.target.value;
    setMobileNumber(value);
    setError("");
  };

  const validateMobileNumber = () => {
    const ukMobileRegex = /^0\d{10}$/;

    if (!ukMobileRegex.test(mobileNumber)) {
      setError("Please enter a valid 11 digit UK mobile number.");
    }
  };

  // Setting today's date
  const today = new Date();
  const formattedDate = `${String(today.getDate()).padStart(2, "0")}/${String(
    today.getMonth() + 1
  ).padStart(2, "0")}/${today.getFullYear()}`;

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          } else {
            setIsVisible(false);
          }
        });
      },
      { threshold: 0.1 }
    );

    if (formRef.current) {
      observer.observe(formRef.current);
    }

    return () => {
      if (formRef.current) {
        observer.unobserve(formRef.current);
      }
    };
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setButtonText("Sending...");

    emailjs
      .sendForm(
        "service_2cnqd96",
        "template_mwguydo",
        e.target,
        "nQBJzdhm_0rQ4QbLv"
      )
      .then(
        (result) => {
          setFormStatus({
            type: "success",
            message: "Form submitted successfully!",
          });
          e.target.reset();
          setShowModal(true);
          setButtonText("Send Message");
        },
        (error) => {
          setFormStatus({
            type: "error",
            message: "There was an issue submitting the form.",
          });
          console.error("Error during form submission:", error);
          alert("Error during form submission:", error);
          setButtonText("Send Message");
        }
      );
  };

  return (
    <section className="container" style={{ marginTop: "50px" }}>
      <div className="row" ref={formRef}>
        <div
          className={`col-md-3 mt-3 ${
            isVisible ? "animate__animated animate__fadeInLeft" : ""
          }`}
        >
          <div className="list-group rounded-0">
            <Link
              to="/referral/dental-implant"
              className="list-group-item list-group-item-action p-3"
            >
              Dental Implant Referral Form
            </Link>
            <Link
              to="/referral/extractions"
              className="list-group-item list-group-item-action p-3"
              aria-current="true"
            >
              Extractions Referral Form
            </Link>
            <Link
              to="/referral/friend"
              className="list-group-item list-group-item-action bg-theme text-white p-3"
              aria-current="true"
            >
              Friends & Family Referral Form
            </Link>
            <Link
              to="/referral/cbct"
              className="list-group-item list-group-item-action p-3"
            >
              CBCT Referral Form
            </Link>
          </div>
        </div>

        <div
          className={`col-md-9 ${
            isVisible ? "animate__animated animate__fadeInRight" : ""
          }`}
        >
          <form ref={formRef} onSubmit={handleSubmit}>
            <div className="card border-0 rounded-3">
              <div className="card-body">
                <h1 className="fw-400">Referral Scheme</h1>
                <p>
                  We know that most new patients come to us because they are
                  recommended by existing patients. That's why we've launched a
                  new referral scheme to reward you when you do just that.
                  <br /> <br />
                  For a limited time, if you refer a new patient to us, we’ll
                  give you a £20 Betty’s Tea Room gift voucher if they go on to
                  have treatment or sign up for our dental plan.
                  <br />
                  <br />
                  So if you know someone who is not happy with their current
                  dentist, is struggling to find an NHS dentist, who is curious
                  about having a smile makeover or who wants treatment like
                  Invisalign, implants or teeth whitening, why not refer them to
                  us? As a thank you, you get a £20 Betty’s Tea Room gift
                  voucher.
                  <br />
                  <br />
                  Simply ask your referral to mention you at their initial
                  appointment with us. Alternatively, you can leave their
                  details in the form below and we will contact them directly.
                  <br />
                  <br />
                  We’re thrilled when you’ve had a good experience with us and
                  spread the word and would love your friends and family to have
                  that experience too. Thanks for your continued support.
                  <br />
                  <br />
                  <span className="fs-14">
                    *to qualify, your referral must have a dental check-up or
                    sign up to our dental plan
                  </span>
                </p>

                <h4 className="border-3 border-bottom py-2 mb-3 fw-400">
                  Refer a patient
                </h4>
                <input
                  type="hidden"
                  name="referral_name"
                  value="Refer a Friend"
                />
                {/** Referring Dentist Details Fields */}
                {[
                  {
                    label: "Your name:*",
                    type: "text",
                    name: "name",
                  },
                  {
                    label: "Referral name:*",
                    type: "text",
                    name: "referral_name2",
                  },
                  {
                    label: "Referral email address:*",
                    type: "email",
                    name: "email",
                  },
                  {
                    label: "Referral telephone number:*",
                    type: "text",
                    name: "practice_phone_number",
                    error: "Please enter a valid 11 digit UK mobile number.",
                  },
                ].map((field, index) => (
                  <div key={index} className="row g-2 align-items-center mb-2">
                    <div className="col-sm-3 text-end fs-14">
                      <label className="col-form-label">{field.label}</label>
                    </div>
                    <div className="col-sm-9">
                      {index === 5 ? (
                        <div>
                          <input
                            type={field.type}
                            className={`form-control rounded-0 ${
                              field.className || ""
                            }`}
                            name={field.name}
                            required
                            pattern="\d{11}"
                            title="Phone number must be 11 digits"
                          />
                          {field.error && (
                            <small className="text-danger">{error}</small>
                          )}
                        </div>
                      ) : (
                        <input
                          type={field.type}
                          className={`form-control rounded-0 ${
                            field.className || ""
                          }`}
                          name={field.name}
                          required
                        />
                      )}
                    </div>
                  </div>
                ))}

                {/** More Information */}
                {/* <div className="row mt-4">
                  <h4 className="border-3 border-bottom py-2 mb-3 fw-400">
                    More Information
                  </h4>
                  <div className="row g-2 align-items-center mb-2">
                    <div className="col-sm-4 text-end fs-14"></div>
                    <div className="col-sm-8">
                      {[
                        "Extraction Placement Only",
                        "Extraction Placement and Restoration",
                        "Extraction Placement and Mentoring for Restoration",
                      ].map((option, index) => (
                        <div key={index} className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="more_info"
                            value={option}
                          />
                          <label className="form-check-label">{option}</label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div> */}

                {/** Special Instructions */}
                <div className="row mt-4">
                  <h4 className="border-3 border-bottom py-2 mb-3 fw-400">
                    Any further information?
                  </h4>
                  <textarea
                    className="form-control rounded-0"
                    rows="5"
                    name="special_instructions"
                    required
                  ></textarea>
                  {/** Practitioner Name and Date */}
                  {/* <div className="row g-2 align-items-center mb-2">
                    <div className="col-sm-3 text-end fs-14">
                      <label className="col-form-label">
                        Practitioner Name:
                      </label>
                    </div>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control rounded-0"
                        name="practitioner_name"
                        required
                      />
                    </div>
                  </div> */}
                  <div className="row g-2 align-items-center mb-2">
                    <input
                      type="hidden"
                      name="date"
                      value={formattedDate}
                    ></input>
                    <div className="col-sm-3 text-start fs-14 text-muted">
                      Date {formattedDate}
                    </div>
                    <div className="col-sm-9">
                      <input type="checkbox" required />
                      <label className="col-form-label ms-2">
                        Please tick this box to agree to our{" "}
                        <a
                          href="/privacy-policy"
                          target="_blank"
                          className="text-golden"
                        >
                          privacy policy
                        </a>{" "}
                        before continuing.
                      </label>
                    </div>
                  </div>
                </div>

                {/** Submit and Clear Buttons */}
                <div className="row">
                  <div className="col-md-3 d-grid">
                    <button
                      type="reset"
                      className="btn btn-light me-2 rounded-0 w-100"
                    >
                      Clear
                    </button>
                  </div>
                  <div className="col-md-9">
                    <button
                      type="submit"
                      className="btn btn-theme rounded-0 w-100"
                    >
                      {buttonText}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* Success Modal */}
      {showModal && (
        <div
          className="modal d-block border-0"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content bg-success text-white">
              <div className="modal-header bg-theme d-flex justify-content-end border-0">
                <a type="button" onClick={() => setShowModal(false)}>
                  <i class="fa fa-times text-white" aria-hidden="true"></i>
                </a>
              </div>
              <div className="modal-body bg-theme p-4 text-center">
                <p className="fs-20">
                  <span className="text-golden">Thank you</span> for getting in
                  touch, one of the team will be in touch soon!
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default DentalImplantReferralForm;
