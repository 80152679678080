import React, { useEffect, useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css";

const GeneralDentistryProcess = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <section
      ref={sectionRef}
      className="d-flex justify-content-center align-items-center"
      style={{ backgroundColor: "#FFFBF1", minHeight: "95vh" }}
    >
      <div className="container">
        <div className="text-center mt-5">
          <h1
            className={`animate__animated ${
              isVisible ? "animate__fadeIn" : "animate__fadeOut"
            }`}
          >
            Regent Dental's Approach to Dental Implants
          </h1>
          <p
            className={`w-75 mx-auto animate__animated ${
              isVisible ? "animate__fadeIn" : "animate__fadeOut"
            }`}
          >
            We use the latest CBCT scanning technology to digitally plan your
            dental implant treatment so that we can place dental implants in the
            most suitable place and with precise positioning for aesthetic
            restorations.
          </p>
        </div>

        <div className="p-4">
          <div className="row justify-content-center align-items-stretch">
            {[
              {
                number: 1,
                title: "Consultation and Planning",
                description:
                  "A thorough dental examination is conducted to assess your oral health and determine your suitability for dental implants.",
              },
              {
                number: 2,
                title: "Implant Placement",
                description:
                  "A titanium implant is surgically inserted into the jawbone, serving as the artificial root for the new tooth.",
              },
              {
                number: 3,
                title: "Osseointegration",
                description:
                  "Over the next few months, the implant fuses with the jawbone, creating a strong, stable foundation.",
              },
              {
                number: 4,
                title: "Crown Placement",
                description:
                  "A custom-made crown is placed on the abutment, completing the restoration and providing a natural-looking, functional tooth.",
              },
            ].map((service, index) => (
              <div className="col-md-4 mb-4" key={index}>
                <div
                  className={`card h-100 card-body border-0 rounded-4 animate__animated ${
                    isVisible ? "animate__fadeInUp" : "animate__fadeOut"
                  }`}
                >
                  <div
                    style={{ height: "50px", width: "50px" }}
                    className="bg-golden rounded-3 text-light fw-700 fs-34 text-center mb-3"
                  >
                    {service.number}
                  </div>
                  <div className="fs-18 fw-600">{service.title}</div>
                  <p className="fs-14">{service.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default GeneralDentistryProcess;
