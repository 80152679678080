import React, { useEffect, useRef, useState } from "react";
import image1 from "../../assets/img/composite-bonding-home.webp";
import image2 from "../../assets/img/652ee79007d8d95c7035c5ee_restorative-dentistry-image.webp.png";
import image3 from "../../assets/img/dental-implants-home.webp";
import image4 from "../../assets/img/invisalign-home.webp";
import image5 from "../../assets/img/general-dentistry-home.webp";
import image6 from "../../assets/img/teeth-whitening-home.webp";
import "animate.css"; // Ensure Animate.css is imported

const TreatmentOptions = () => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef();

  const handleScroll = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setIsVisible(true); // Set to true when the section is visible
      } else {
        setIsVisible(false); // Set to false when the section is not visible
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleScroll, {
      threshold: 0.1, // Adjust threshold as needed
    });
    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <div ref={ref} className="row hero-3 p-5">
      <div className="col-12">
        <div className="container d-flex justify-content-center mt-5">
          <div className="text-center">
            <p
              className={` ${
                isVisible
                  ? "animate__animated animate__zoomIn"
                  : "animate__animated animate__fadeOut"
              }`}
            >
              Treatment options
            </p>
            <h1
              className={` text-theme ${
                isVisible
                  ? "animate__animated animate__zoomIn"
                  : "animate__animated animate__fadeOut"
              }`}
            >
              Dental treatments in a relaxed,
              <br /> judgment-free environment
            </h1>
            <p
              className={`p-0 p-md-5 ${
                isVisible
                  ? "animate__animated animate__zoomIn"
                  : "animate__animated animate__fadeOut"
              }`}
            >
              Get the smile you've always dreamed of. Our industry-leading
              treatment options, and flexible financing choices make it easier
              than ever to attain your healthiest and most confident smile.
            </p>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="container">
          <div className="row">
            {treatmentData.map((treatment, index) => (
              <div className="col-md-6 col-lg-4 mb-5" key={index}>
                <div
                  className={`card d-flex flex-column h-100 border-golden rounded ${
                    isVisible
                      ? "animate__animated animate__zoomIn"
                      : "animate__animated animate__fadeOut"
                  }`}
                >
                  <img
                    className="card-img-top"
                    src={treatment.image}
                    alt={treatment.alt}
                  />
                  <h4 className="card-title bg-theme text-light p-3">
                    {treatment.title}
                  </h4>
                  <div className="card-body d-flex flex-column flex-grow-1">
                    <p className="card-text">{treatment.description}</p>
                    <div className="mt-auto">
                      <a
                        href={treatment.link}
                        className="text-decoration-none text-golden"
                      >
                        {treatment.btn}{" "}
                        <i className="fa fa-arrow-right ms-2"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

// Sample data for treatment options
const treatmentData = [
  {
    title: "Invisalign\u00AE",
    alt: "female Invisalign patient smiling with clear retainer",
    image: image4,
    description:
      "With Invisalign®, you can straighten your teeth comfortably and discreetly, so you can smile confidently throughout your journey to a perfectly aligned smile.",
    link: "/invisalign",
    btn: "Discover Invisalign® Clear Aligners",
  },
  {
    title: "Dental Implants",
    alt: "dental implant fitting procedure",
    image: image3,
    description:
      "With dental implants, you can replace any gaps to regain your smile’s natural look and feel, giving you the confidence to eat, speak, and smile without worry.",
    link: "/dental-implants",
    btn: "Discover Dental Implants",
  },
  {
    title: "Teeth Whitening",
    alt: "teeth whitening before and after",
    image: image6,
    description:
      "Whitening treatments designed to safely lift stains and enhance your teeth’s natural radiance, giving you a fresh, confident look.",
    link: "/teeth-whitening",
    btn: "Explore Professional Teeth Whitening",
  },
  {
    title: "Composite Bonding",
    alt: "composite bonding before and after",
    image: image1,
    description:
      "Composite bonding is perfect for fixing chips, gaps, or uneven edges, a revolutionary option which is a fast and effective way to elevate your smile with stunning results. ",
    link: "/smile-fast-composite-bonding",
    btn: "Explore SmileFast Composite Bonding",
  },
  {
    title: "All On 4 Implants",
    alt: "all on 4 dental implant dentist and patient",
    image: image2,
    description:
      "Transform your smile with All-on-4 implants — a revolutionary solution for a full, natural-looking set of teeth in just one day.",
    link: "/all-on-4-dental-implants",
    btn: "Learn More About All On 4 Implants",
  },
  {
    title: "General Dentistry",
    alt: "private dental check up at regent dental care",
    image: image5,
    description:
      "Maintain a healthy, beautiful smile with our comprehensive general dentistry services! From routine cleanings to preventive care, we’re here to keep your teeth and gums in top shape.",
    link: "/general-dentistry",
    btn: "Explore General Dentistry",
  },
];

export default TreatmentOptions;
