// src/components/SmileSection.js
import React, { useEffect, useRef, useState } from "react";
import Hero from "../../assets/img/contact-invitation.jpeg";
import "animate.css";

const HeroInvitation = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          } else {
            setIsVisible(false); // Set to false when the section is not visible
          }
        });
      },
      { threshold: 0.1 } // Adjust the threshold as needed
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <div
      className="d-flex justify-content-center align-items-center position-relative"
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6)), url(${Hero})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "70vh",
      }}
      ref={sectionRef}
    >
      <div className="text-light text-center">
        <h1
          className={`fs-60 ${
            isVisible
              ? "animate__animated animate__zoomIn"
              : "animate__animated animate__fadeOut"
          }`}
        >
          REGENT DENTAL -{" "}
          <span className="text-golden">LAUNCH DAY INVITATION</span>
        </h1>
      </div>
    </div>
  );
};

export default HeroInvitation;
