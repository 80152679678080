import React, { useEffect, useRef, useState } from "react";
import "animate.css"; // Ensure Animate.css is imported

const BookingCTA = () => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef();

  const handleScroll = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setIsVisible(true); // Set to true when the section is visible
      } else {
        setIsVisible(false); // Set to false when the section is not visible
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleScroll, {
      threshold: 0.1, // Adjust threshold as needed
    });
    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <div ref={ref} className="container-fluid">
      <div className="row align-items-center pt-2 justify-content-center bg-theme">
        <div
          className="col-lg-6 cta-background-new d-flex justify-content-center align-items-center text-center "
          style={{ minHeight: "500px" }}
        >
          <div className="px-0 px-lg-5">
            <h1
              className={`text-golden mt-5 fs-40 ${
                isVisible
                  ? "animate__animated animate__bounceIn"
                  : "animate__animated animate__bounceOut"
              }`}
            >
              Ready to book your appointment?
            </h1>
            <p
              className={`text-light px-0 px-md-5 mt-5 fs-18 ${
                isVisible
                  ? "animate__animated animate__bounceIn"
                  : "animate__animated animate__bounceOut"
              }`}
            >
              Schedule your appointment today and experience the Regent Dental
              difference. We provide flexible payment and financing options,
              ensuring a stress-free dental experience.
            </p>
            <div className="row gap-0 mb-3 d-flex">
              <div className="col-md-12 mb-3 d-flex justify-content-center">
                <a
                  href="/contact-us"
                  className={`btn btn-theme ${
                    isVisible
                      ? "animate__animated animate__bounceIn"
                      : "animate__animated animate__bounceOut"
                  }`}
                >
                  Book an Appointment
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 text-white">
          <div style={{ maxWidth: "500px", margin: "auto" }}>
            <p
              className={`text-light px-0 fs-18 ${
                isVisible
                  ? "animate__animated animate__bounceIn"
                  : "animate__animated animate__bounceOut"
              }`}
            >
              Regent Dental are a leading private dental practice servicing all
              of West Yorkshire. We provide advanced dentistry, teeth
              straightening, dental implants and more to West Yorkshire,
              including:
            </p>
            <ul
              className={`text-light d-flex flex-column gap-1 px-0 px-md-4 mt-2 fs-16 ${
                isVisible
                  ? "animate__animated animate__bounceIn"
                  : "animate__animated animate__bounceOut"
              }`}
            >
              <li>Ilkley</li>
              <li>Keighley</li>
              <li>Skipton</li>
              <li>Otley</li>
              <li>Silsden</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    // <div ref={ref} className="container-fluid">
    //   <div className="row align-items-center justify-content-center bg-theme">
    //     <div
    //       className="cta-background d-flex justify-content-center align-items-center text-center w-50"
    //       style={{ minHeight: "500px" }}
    //     >
    //       <div className="px-0 px-lg-5">
    //         <h1
    //           className={`text-golden mt-5 fs-40 ${
    //             isVisible
    //               ? "animate__animated animate__bounceIn"
    //               : "animate__animated animate__bounceOut"
    //           }`}
    //         >
    //           Ready to book your appointment?
    //         </h1>
    //         <p
    //           className={`text-light px-0 px-md-5 mt-5 fs-18 ${
    //             isVisible
    //               ? "animate__animated animate__bounceIn"
    //               : "animate__animated animate__bounceOut"
    //           }`}
    //         >
    //           Schedule your appointment today and experience the Regent Dental
    //           difference. We provide flexible payment and financing options,
    //           ensuring a stress-free dental experience.
    //         </p>
    //         <div className="row gap-0 mb-3 d-flex">
    //           <div className="col-md-12 mb-3 d-flex justify-content-center">
    //             <a
    //               href="/contact-us"
    //               className={`btn btn-theme ${
    //                 isVisible
    //                   ? "animate__animated animate__bounceIn"
    //                   : "animate__animated animate__bounceOut"
    //               }`}
    //             >
    //               Book an Appointment
    //             </a>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default BookingCTA;
