import React, { useEffect, useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css";
import docImage from "../../assets/img/doc-white.png"; // Import your image

const DentalCareSection = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null); // Reference to the section for intersection observer

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true); // Trigger animation when in view
        } else {
          setIsVisible(false); // Reset visibility when out of view
        }
      },
      { threshold: 0.1 } // Adjust threshold as needed
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <section ref={sectionRef} className="bg-theme">
      <div className="container">
        <div className="row align-items-center">
          <div
            className={`col-md-6 mt-4 animate__animated ${
              isVisible ? "animate__fadeInLeft" : "animate__fadeOut"
            }`}
          >
            <img
              src={docImage}
              className="img-fluid"
              alt="Receptionist at Regent Dental Care"
            />
          </div>

          <div
            className={`col-md-6 text-light mt-4 animate__animated ${
              isVisible ? "animate__fadeInRight" : "animate__fadeOut"
            }`}
          >
            <p>Dental Care You'll Love</p>

            <h1 className="fs-48">
              Sculpting Smiles with Precision and Artistry
            </h1>

            <p>
              {/* Composite bonding involves the application of a tooth-coloured resin material to reshape, rebuild, or whiten teeth. It is placed with a stent and hardened with a special light, then polished to blend seamlessly with the rest of your teeth. */}
              Our skilled cosmetic dentists offer composite bonding in Ilkley
              and to our many satisfied patients in West Yorkshire.
            </p>

            <p>
              {/* Composite bonding is a popular choice for those looking to make
              cosmetic improvements without the need for more invasive
              procedures. It is ideal for repairing chips, closing gaps,
              reshaping, or improving the colour of teeth. Depending on the
              complexity, it can be done in just one visit. */}
              Composite bonding involves the application of a tooth-coloured
              resin material to reshape, rebuild, or whiten teeth. It is placed
              with a stent and hardened with a special light, then polished to
              blend seamlessly with the rest of your teeth.
            </p>
            <p>
              Composite bonding is a popular choice for those looking to make
              cosmetic improvements without the need for more invasive
              procedures. It is ideal for repairing chips, closing gaps,
              reshaping, or improving the colour of teeth. Depending on the
              complexity, it can be done in just one visit.
            </p>

            <a href="/contact-us" className="btn btn-golden border-0 mt-4 mb-5">
              Book an Appointment
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DentalCareSection;
