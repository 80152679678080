import React, { useEffect, useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css";

import icon1 from "../../assets/img/Frame.png";
import icon2 from "../../assets/img/orthodontic 1.png";
import icon3 from "../../assets/img/Frame-11.png";
import icon4 from "../../assets/img/Group...png";
import icon5 from "../../assets/img/Frame12.png";

const DiscreetBenefits = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  const benefits = [
    {
      number: icon1,
      title: "Results That Surprise",
      description:
        "Although the real science and innovation behind all on four dental implants go on below the gums, you’ll be delighted by your new smile. Every smile is made to match your natural appearance to help you smile more than ever.",
    },
    {
      number: icon2,
      title: "Get Back to Living",
      description:
        "Losing teeth can affect your ability to eat, speak, smile, and laugh - all the best things in life. With all on four dental implants we change all that so you live your best life again without worrying about your teeth.",
    },
    {
      number: icon3,
      title: "Same Day Smiles",
      description:
        "Our all on four dental implant procedure reduces the number of stages needed to achieve the perfect smile. Often referred to as “same day teeth”, “same day smiles” or “smile in a day”, we place all your implants and give you natural, functioning temporary teeth in one day. Once everything has had a chance to settle and heal, we’ll replace the temporary teeth with a final restoration.",
    },
    {
      number: icon4,
      title: "Improved Health",
      description:
        "Losing teeth isn’t usually the end of dental problems—gum disease and jaw problems often come after. Dental implants help preserve your bone and gums and stop other problems occurring.",
    },
    {
      number: icon5,
      title: "An Investment That Lasts",
      description:
        "Decades of research shows titanium implants are strong, durable and able to prove an excellent long-term foundation for your smile.",
    },
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <section
      ref={sectionRef}
      className="d-flex justify-content-center align-items-center"
      style={{ minHeight: "95vh" }}
    >
      <div>
        <div className="text-center mb-5 mt-5">
          <h1
            className={`animate__animated ${
              isVisible ? "animate__fadeIn" : ""
            }`}
          >
            Why Choose All on Four Dental Implants?
          </h1>
        </div>

        <div className="container mt-5 align-items-stretch">
          <div className="row justify-content-center">
            {benefits.map((benefit, index) => (
              <div className="col-md-4 mb-4" key={index}>
                <div
                  className={`card h-100 card-body border-0 rounded-4 text-center d-flex justify-content-center animate__animated ${
                    isVisible ? "animate__fadeInUp" : ""
                  }`}
                  style={{ backgroundColor: "#F3F4F6" }}
                >
                  <div className="card-body">
                    <div
                      style={{ height: "50px", width: "50px" }}
                      className="bg-golden rounded-3 mb-2 d-flex justify-content-center align-items-center mx-auto"
                    >
                      <img src={benefit.number} className="img-fluid"></img>
                    </div>
                    <div className="fs-18 fw-600">{benefit.title}</div>
                    <p className="fs-14">{benefit.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default DiscreetBenefits;
