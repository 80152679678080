import React, { useEffect, useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css";
import teamImage from "../../assets/img/64e76a74015a878b61b17cf0_swish-team-image-p-800.webp.png"; // Adjust the path as necessary

const RegentDentalExperience = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <section
      ref={sectionRef}
      className=" hero-3 d-flex justify-content-center align-items-center bg-theme text-light p-4"
      style={{ minHeight: "85vh" }}
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6 px-lg-3">
            <div className="mb-5">
              <h1
                className={`animate__animated ${
                  isVisible ? "animate__zoomIn" : "animate__fadeOut"
                }`}
              >
                What Does Denplan Essentials{" "}
                <span className="text-golden">Include?</span>
              </h1>
              <ul
                className={`animate__animated ${
                  isVisible ? "animate__zoomIn" : "animate__fadeOut"
                }`}
              >
                <li
                  className={`animate__animated ${
                    isVisible ? "animate__zoomIn" : "animate__fadeOut"
                  }`}
                >
                  Dental check-ups{" "}
                </li>
                <li
                  className={`animate__animated ${
                    isVisible ? "animate__zoomIn" : "animate__fadeOut"
                  }`}
                >
                  Dental hygiene visits{" "}
                </li>
                <li
                  className={`animate__animated ${
                    isVisible ? "animate__zoomIn" : "animate__fadeOut"
                  }`}
                >
                  Dental x-rays{" "}
                </li>
                <li
                  className={`animate__animated ${
                    isVisible ? "animate__zoomIn" : "animate__fadeOut"
                  }`}
                >
                  Preventive dental advice and therapy{" "}
                </li>
                <li
                  className={`animate__animated ${
                    isVisible ? "animate__zoomIn" : "animate__fadeOut"
                  }`}
                >
                  <span className="text-golden fw-600"> 10% of ALL </span>{" "}
                  general treatments (excludes cosmetic and implant dentistry){" "}
                </li>
              </ul>
            </div>
            <div>
              <h1
                className={`animate__animated ${
                  isVisible ? "animate__zoomIn" : "animate__fadeOut"
                }`}
              >
                <span className="text-golden">Why</span> choose Denplan
                Essentials?
              </h1>
              <ul
                className={`animate__animated ${
                  isVisible ? "animate__zoomIn" : "animate__fadeOut"
                }`}
              >
                <li
                  className={`animate__animated ${
                    isVisible ? "animate__zoomIn" : "animate__fadeOut"
                  }`}
                >
                  Encourages you to attend regular dental visits
                </li>
                <li
                  className={`animate__animated ${
                    isVisible ? "animate__zoomIn" : "animate__fadeOut"
                  }`}
                >
                  Allows for you to budget for routine oral care
                </li>
                <li
                  className={`animate__animated ${
                    isVisible ? "animate__zoomIn" : "animate__fadeOut"
                  }`}
                >
                  You can join the practice easily by signing up online
                </li>
                <li
                  className={`animate__animated ${
                    isVisible ? "animate__zoomIn" : "animate__fadeOut"
                  }`}
                >
                  Simple to set up with no patient assessments required
                </li>
                <li
                  className={`animate__animated ${
                    isVisible ? "animate__zoomIn" : "animate__fadeOut"
                  }`}
                >
                  Discounted restorative dentistry
                </li>
              </ul>
            </div>
          </div>

          <div className="col-md-6 p-4 mt-3">
            <img
              src={teamImage}
              className={`img-fluid shadow-golden-2 animate__animated ${
                isVisible ? "animate__zoomIn" : "animate__fadeOut"
              }`}
              style={{
                borderTopLeftRadius: "350px",
                borderTopRightRadius: "350px",
              }}
              alt="staff team at regent dental care"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default RegentDentalExperience;
