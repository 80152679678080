import React, { useEffect, useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css";
import docImage from "../../assets/img/doc-white.png"; // Import your image

const DentalCareSection = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null); // Reference to the section for intersection observer

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true); // Trigger animation when in view
        } else {
          setIsVisible(false); // Reset visibility when out of view
        }
      },
      { threshold: 0.1 } // Adjust threshold as needed
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <section ref={sectionRef} className="bg-theme">
      <div className="container">
        <div className="row align-items-center">
          <div
            className={`col-md-6 mt-4 animate__animated ${
              isVisible ? "animate__fadeInLeft" : "animate__fadeOut"
            }`}
          >
            <img
              src={docImage}
              className="img-fluid"
              alt="Receptionist at Regent Dental Care"
            />
          </div>

          <div
            className={`col-md-6 text-light mt-4 animate__animated ${
              isVisible ? "animate__fadeInRight" : "animate__fadeOut"
            }`}
          >
            <p>Dental Care You'll Love</p>

            <h1 className="fs-48">
              Invisalign® Clear Aligners for Discreet Orthodontics
            </h1>

            <p>
              {/* Invisalign® is an innovative orthodontic system that uses custom-made, clear plastic aligners to gradually straighten teeth. 
                        It's the ideal solution for people looking to improve their smile without the appearance of traditional metal braces. */}
              Invisalign® is an innovative orthodontic system that uses
              custom-made, clear plastic aligners to gradually straighten teeth.
              It's the ideal solution for people looking to improve their smile
              without the appearance of traditional metal braces, offering
              near-invisible and predictable teeth straightening.
            </p>
            <p>
              {/* Invisalign® aligners are virtually invisible, comfortable to wear,
              and removable for eating, brushing, and flossing; making them less
              intrusive on your lifestyle. They offer a flexible and convenient
              solution for those who want to straighten their teeth while
              maintaining a natural look. */}
              Invisalign® aligners are virtually invisible, comfortable to wear,
              and removable for eating, brushing, and flossing; making them less
              intrusive on your lifestyle. They offer a flexible and convenient
              solution for those who want to straighten their teeth while
              maintaining a natural look. Get in touch with us to discuss your
              needs with a leading Invisalign provider in Ilkley.
            </p>

            <a href="/contact-us" className="btn btn-golden border-0 mt-4 mb-5">
              Book an Appointment
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DentalCareSection;
