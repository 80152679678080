import React, { useEffect, useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import icon1 from "../../assets/img/transform-dental-implants.webp";
const TransformYourSmile = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);
  return (
    <section
      ref={sectionRef}
      className="d-flex justify-content-center align-items-center bg-theme py-5"
    >
      <div className="container">
        <div className="row flex-row-reverse align-items-center">
          <div className="col-lg-6 px-lg-3">
            <h2
              className={`fs-48 text-white mb-3 animate__animated ${
                isVisible ? "animate__fadeInRight" : ""
              }`}
            >
              Transform Your Smile With Dental Implants
            </h2>
            <p
              className={`fs-40 text-white animate__animated ${
                isVisible ? "animate__fadeInRight" : ""
              }`}
            >
              “They look great and I feel confident again, I can eat and drink
              with liberty”
            </p>
          </div>
          <div className="col-lg-6 px-lg-3">
            <img
              src={icon1}
              className={`w-100  animate__animated ${
                isVisible ? "animate__fadeInLeft" : ""
              }`}
              alt="dental implant treatment at Regent Dental Care"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default TransformYourSmile;
