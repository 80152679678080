import React, { useEffect, useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css";
import docImage from "../../assets/img/doc-white.png"; // Import your image

const DentalCareSection = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null); // Reference to the section for intersection observer

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true); // Trigger animation when in view
        } else {
          setIsVisible(false); // Reset visibility when out of view
        }
      },
      { threshold: 0.1 } // Adjust threshold as needed
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <section ref={sectionRef} className="bg-theme">
      <div className="container">
        <div className="row align-items-center">
          <div
            className={`col-md-6 mt-4 animate__animated ${
              isVisible ? "animate__fadeInLeft" : "animate__fadeOut"
            }`}
          >
            <img
              src={docImage}
              className="img-fluid"
              alt="Receptionist at Regent Dental Care"
            />
          </div>

          <div
            className={`col-md-6 text-light mt-4 animate__animated ${
              isVisible ? "animate__fadeInRight" : "animate__fadeOut"
            }`}
          >
            <p>Dental Care You'll Love</p>

            <h1 className="fs-48">Straighten Your Teeth While you Sleep</h1>

            <p>
              These aligners use a patented hyper-elastic polymer that delivers
              gentle yet consistent forces to move teeth efficiently. Despite
              the reduced wear time, NiTime Aligners make an excellent
              alternative for people seeking a more flexible approach to
              orthodontics.
            </p>
            <p>
              If you struggle to wear aligners for 22 hours due to your career
              or lifestyle then these aligners are perfect for you. During the
              day you can eat, drink, work and go about your life as normal and
              then slip your aligners in each evening taking discretion to a
              whole new level.
            </p>

            <a href="/contact-us" className="btn btn-golden border-0 mt-4 mb-5">
              Book an Appointment
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DentalCareSection;
