import { Helmet } from "react-helmet";
import Navbar from "../components/universal/navbar";
import Header from "../components/homepage/hero";
import About from "../components/homepage/about";
import Services from "../components/homepage/services";
import Testimonials from "../components/universal/testimonials";
import TeamHero from "../components/homepage/teamHero";
import PaymentPlan from "../components/homepage/paymentPlans";
import CTA from "../components/universal/cta";
import Contact from "../components/universal/contact";
import Footer from "../components/universal/footer";
import LeadGenerationModal from "../components/universal/contact-modal";

function Index() {
  return (
    <div>
      <Helmet>
        <title>
          Private Dentist in Ilkley, West Yorkshire | Regent Dental{" "}
        </title>
        <meta
          name="description"
          content="Discover exceptional dental care at our private dentist in Ilkley, West Yorkshire. Providing comprehensive dental care tailored to your needs."
        />
        <link rel="icon" href="/favicon.png" />
      </Helmet>
      <LeadGenerationModal />
      <Navbar />
      <Header />
      <About />
      <Services />
      <Testimonials />
      <TeamHero />
      <PaymentPlan />
      <CTA />
      <Contact />
      <Footer />
    </div>
  );
}

export default Index;
