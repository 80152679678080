import React, { useEffect, useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css";
import teamImage from "../../assets/img/64e76a74015a878b61b17cf0_swish-team-image-p-800.webp.png"; // Adjust the path as necessary

const RegentDentalExperience = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <section
      ref={sectionRef}
      className="hero-3 d-flex justify-content-center align-items-center bg-theme text-light p-4"
      style={{ minHeight: "85vh" }}
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6 px-lg-3">
            <div>
              <p
                className={`animate__animated ${
                  isVisible ? "animate__zoomIn" : "animate__fadeOut"
                }`}
              >
                The Regent Dental Experience
              </p>
              <h1
                className={`animate__animated ${
                  isVisible ? "animate__zoomIn" : "animate__fadeOut"
                }`}
              >
                Aftercare and <span className="text-golden"> Recovery</span>
              </h1>
              <p
                className={`animate__animated ${
                  isVisible ? "animate__zoomIn" : "animate__fadeOut"
                }`}
              >
                Dental implants require good oral hygiene by brushing and
                flossing regularly and attending follow-up appointments. It's
                also important to avoid anything that can hinder the healing
                process and affect the long-term success of the implant.
              </p>
            </div>
            <div>
              <h1
                className={`animate__animated ${
                  isVisible ? "animate__zoomIn" : "animate__fadeOut"
                }`}
              >
                Filling the Gaps for a{" "}
                <span className="text-golden">Complete Smile</span>
              </h1>
              <p
                className={`animate__animated ${
                  isVisible ? "animate__zoomIn" : "animate__fadeOut"
                }`}
              >
                Implants will help resolve aesthetics and importantly they
                restore the functionality that may have stopped you being able
                to eat your favourite foods.
              </p>
            </div>
            <div>
              <a
                href="/team"
                className={`btn btn-golden mt-5 animate__animated ${
                  isVisible ? "animate__zoomIn" : "animate__fadeOut"
                }`}
              >
                Meet the Team
              </a>
            </div>
          </div>

          <div className="col-md-6 p-4 p-lg-5 mt-5">
            <img
              src={teamImage}
              className={`img-fluid shadow-golden-2 animate__animated ${
                isVisible ? "animate__zoomIn" : "animate__fadeOut"
              }`}
              style={{
                borderTopLeftRadius: "350px",
                borderTopRightRadius: "350px",
              }}
              alt="staff team at regent dental care"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default RegentDentalExperience;
